import Head from "next/head";
// import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import introStyle from "../src/styles/sass/intropage.module.scss";
import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Slider from "react-slick";
import { END } from "redux-saga";
//import IntroVideo from "../public/assets/new-intro-video.mp4";
//import IntroVideoMobile from "../public/assets/new-intro-video-mobile.mp4";
import IntroPoster from "../public/assets/intro-img.jpg";
import videojs from "video.js";
import Isimg1 from "../public/assets/intro-img-1.jpg";
import Isimg2 from "../public/assets/intro-img-2.jpg";
import Isimg3 from "../public/assets/intro-img-3.jpg";
import Isimg4 from "../public/assets/intro-img-4.jpg";
import Isimg5 from "../public/assets/intro-img-5.jpg";

import { wrapper } from "../src/config/Store";
import { getAllCMS } from "../src/redux/cms/CMSActions";
import constants from "../src/utils/Constants";
import { useSelector } from "react-redux";
import Home from "../src/components/pages/home/Home";
import Header from "../src/components/header/Header";
import {
  homeBodySchema,
  homeFAQBodySchema,
  schemaHome,
  homeVideoSchema
} from "../GO-Bermondsey_Schema";
import { resourceTypes } from "../src/utils/CommonFunctions";
import OfferSticker from "../src/offer/components/OfferSticker";
import Footer from "../src/components/footer/Footer";
import Routes from "../src/routes/Routes";

const { CMS_TYPES, CMS_PAGES } = constants;

const nextIntropage = ({ popup, openPopup }) => {
  const { cmsMap, boards } = useSelector((state) => state?.cms);
  const cmsBoard = useMemo(
    () => boards?.[JSON.stringify({ type: "Slider" })],
    [boards]
  );

  const [reinitSliderhome, setReinitSliderhome] = useState(false);

  const [contentPopup, setcontentPopup] = useState(false);

  const [sliderData, setSliderData] = useState(false);
  const videoPlayerRef = useRef(null); // Instead of ID
  const [currentTime, setCurrentTime] = useState(null);
  const [sliderLength, setSliderLength] = useState(false);
  const [showLandingPage, setShowLandingPage] = useState(false);

  const router = useRouter();


  const videoJSOptions = {
    autoplay: "muted",
    controls: false,
    poster: IntroPoster.src,
    userActions: { hotkeys: true },
    playbackRates: [0.5, 1, 1.5, 2],
    controlBar: {
      playToggle: false,
      captionsButton: false,
      chaptersButton: false,
      subtitlesButton: false,
      remainingTimeDisplay: false,
      progressControl: {
        seekBar: true,
      },
      fullscreenToggle: false,
      playbackRateMenuButton: false,
    },
  };

  // useEffect(() => {
  //   document.body.classList.add("no_header");

  //   return () => {
  //     document.body.classList.remove("no_header");
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  

  useEffect(() => {
    if(showLandingPage){
    if (videoPlayerRef.current) {
      const player = videojs(videoPlayerRef.current, videoJSOptions, () => {
        //  player.src(window.innerWidth <= 767 ? IntroVideoMobile : IntroVideo);
        player.src(
          window.innerWidth <= 767
            ? "../assets/new-intro-video-mobile.mp4"
            : "../assets/new-intro-video.mp4"
        );
        player.on("ended", () => {
          console.log("ended");
          setSliderData(true);
        });
        player.on("timeupdate", () => {
          setCurrentTime(player.currentTime());
        });
        console.log("Player Ready");
      });
    }
    }
    
  }, [showLandingPage]);

  useEffect(() => {
    if (router.pathname == "/" && router.query.screen == "intro-video") {
      setShowLandingPage(true);
    }
  }, [router?.query?.screen]);



  const settings = useMemo(
    () => ({
    dots: false,
    infinite: false,
    fade: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    beforeChange: (current, next) => {
      if (next === 3) {
        //setSliderLength(true);
        router.push(Routes?.book_a_visit);
      }
    },
 }),
    [showLandingPage]
  );

  const SliderContainer = ({ sliderLayer }) => {
    return (
      <div className={sliderLayer ? "" : "hideElement"}>
        <div
          className={introStyle.intro_slider_inner_container}
          style={{ height: "100%" }}
        >
          <Slider {...settings}>
            {[Isimg2, Isimg3, Isimg4, Isimg5].map((introImg, i) => (
              <div key={i} className={introStyle.is_o_cb}>
                <div
                  className={introStyle.is_innerItem}
                  style={{ backgroundImage: `url(${introImg.src})` }}
                />
              </div>
            ))}
          </Slider>

          <div className={introStyle.is_i_txt_cb}>
            {cmsBoard?.list?.map((id, i) => {
              const mCmsSlider = cmsMap?.[id];
              const resource_type = mCmsSlider?.meta?.resource_type;
              const resourceType = resourceTypes?.[resource_type];

              if (!resourceType) return null;

              const heading = mCmsSlider?.name || resourceType?.name;
              const resourcePrice = mCmsSlider?.price;

              let price;
              if (resourcePrice) {
                price = mCmsSlider?.price;
              } else if (resourcePrice === 0) {
                price = `tbc`;
              }

              return (
                <p key={i}>
                  {heading} <span> from {price}</span>
                </p>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const contentPopupEvent = (e) => {
    setcontentPopup(e);
  };

  const mPageDetails = useSelector(({ cms }) => {
    const boardKey = JSON.stringify({
      type: CMS_TYPES.PAGE_META,
      page_name: CMS_PAGES.HOME_PAGE,
    });

    const boards = cms.boards;
    const mPageDetailsId = boards[boardKey]?.list?.[0];

    return Object.assign({}, cms.cmsMap[mPageDetailsId]);
  });

  const mOfferPageDetails = useSelector(({ cms }) => {
    const offerBoardKey = JSON.stringify({
      type: CMS_TYPES.PAGE_META,
      page_name: CMS_PAGES.OFFER,
    });

    const boards = cms.boards;
    const mPageDetailsId = boards[offerBoardKey]?.list?.[0];

    return Object.assign({}, cms.cmsMap[mPageDetailsId]);
  });
  const { offerTag } = useMemo(
    () => Object.assign({}, mOfferPageDetails?.meta),
    [mOfferPageDetails?.meta]
  );

  const onOfferTag = useCallback(() => {
    if (offerTag?.navigate_to) router.push(`/offer/${offerTag?.navigate_to}`);
  }, [offerTag?.navigate_to]);

  return (
    <>
      <Head>
        <title>{mPageDetails?.name}</title>
        <meta name="description" content={mPageDetails?.description} />
        <meta itemProp="name" content="" />
        <meta itemProp="description" content="" />
        <meta itemProp="image" content="" />
        <link rel="canonical" href={`${Routes?.domain}`}/>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: schemaHome,
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: homeVideoSchema,
          }}
        />

        
      </Head>
      
      {!showLandingPage ? (
        <>
        <Header
              popup={popup}
              openPopup={openPopup}
              reinitSliderhome={reinitSliderhome}
              setReinitSliderhome={setReinitSliderhome}
              showLandingPage={showLandingPage}
              setShowLandingPage={ setShowLandingPage}
          />

          <Home
            popup={popup}
            openPopup={openPopup}
            reinitSliderhome={reinitSliderhome}
            contentPopup={contentPopup}
            contentPopupEvent={contentPopupEvent}
            showLandingPage={showLandingPage}
          />
            <Footer />
        </>
       
      ) : (
        <>
          <div
            className={sliderData ? "hideElement" : introStyle.intro_overlay}
            currentdatatime={currentTime}
          >
            <video playsInline ref={videoPlayerRef} className="video-js" />
          </div>

          <SliderContainer sliderLayer={sliderData} />

          {/* <OfferSticker
            containerClassName={`pointer ${introStyle["offer-sicker-container"]
              } ${offerTag?.active_tag ? "" : "hideElement"}`}
            headingClassName={introStyle["heading"]}
            heading={`${offerTag?.offer_name}`.replace(" ", "\n")}
            descriptionClassName={introStyle["description"]}
            description="Click Here"
            onClick={onOfferTag}
          /> */}

          <div className={introStyle.intro_btn}>
            <Link href={Routes?.book_a_visit}>
              <a className="availability-btn">Book a Visit</a>
            </Link>

            <Link href={Routes?.home}>
              <a className="skip-intro-btn">Skip Intro</a>
            </Link>
            {/* <a
              className="skip-intro-btn"
              onClick={() => setShowLandingPage(false)}
            >
              Skip Intro
            </a> */}
          </div>
        </>
      )}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: homeBodySchema,
        }}
      />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: homeFAQBodySchema,
        }}
      />
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async (context) => {
    const cmsState = store.getState().cms;

     const homeMetaParams = {
          type: CMS_TYPES.PAGE_META,
          page_name: CMS_PAGES.HOME,
      };
      const homeMetaBoard =
          cmsState.boards[JSON.stringify(homeMetaParams)];
      if (!homeMetaBoard) {
          store.dispatch(getAllCMS(homeMetaParams));
      }


    const homePageMetaParams = {
      type: CMS_TYPES.PAGE_META,
      page_name: CMS_PAGES.HOME_PAGE,
    };

    const homePageMetaBoard =
      cmsState.boards[JSON.stringify(homePageMetaParams)];
    if (!homePageMetaBoard) {
      store.dispatch(getAllCMS(homePageMetaParams));
    }

    const sliderParams = { type: CMS_TYPES.SLIDER };
    const homeSliderBoard = cmsState.boards[JSON.stringify(sliderParams)];
    if (!homeSliderBoard) {
      store.dispatch(getAllCMS(sliderParams));
    }

    const offerPageDetailsParams = {
      type: CMS_TYPES.PAGE_META,
      page_name: CMS_PAGES.OFFER,
    };
    const pageDetailsBoard =
      cmsState.boards[JSON.stringify(offerPageDetailsParams)];

    if (!pageDetailsBoard) {
      store.dispatch(getAllCMS(offerPageDetailsParams));
    }

    const homePageParams = { type: CMS_TYPES.HOME_PAGE };
    const homePageBoard = cmsState.boards[JSON.stringify(homePageParams)];

    if (!homePageBoard) {
      store.dispatch(getAllCMS(homePageParams));
    }

    const testimonialsParams = { type: CMS_TYPES.TESTIMONIAL };
    const testimonialsBoard = cmsState.boards[JSON.stringify(testimonialsParams)];

    if (!testimonialsBoard) {
      store.dispatch(getAllCMS(testimonialsParams));
    }

    if (!homePageMetaBoard || !homeSliderBoard || !pageDetailsBoard || !homePageBoard || !testimonialsBoard) {
      store.dispatch(END);
    }

    await store.sagaTask.toPromise();

    return {};
  }
);

export default nextIntropage;
